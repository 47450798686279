<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">执法检查</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex flex-wrap align-items-center">
				<div>提交时间：</div>
				<el-date-picker style="width: 400px;" class="m-1" size="small" @change="changeTime" v-model="time"
					type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
				</div>
				<div class="d-flex align-items-center m-2">
					<div class="label ">状态：</div>
					<el-select size="small" style="width: 120px" v-model="search.status" @change="toSearch"
						placeholder="状态">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="未处理" value="10"></el-option>
						<el-option label="整改" value="20"></el-option>
					</el-select>
				</div>
				<el-button @click="handleExport" class="ml-2" type="primary" size="mini">导出</el-button>
			</div>
			<el-table border stripe class="mt-3" :data="lists.data" style="width: 100%" :default-expand-all="true">
				<el-table-column type="expand">
					<template slot-scope="scope">
						<div class="d-flex">
							<div>地址：{{scope.row.address}}</div>
						</div>
						<div class="d-flex">
							违规住人检查：{{scope.row.content}}
						</div>
						<template v-if="scope.row.image.length > 0 || scope.row.video != ''">
							<div class="d-flex">
								<div v-if="scope.row.image.length > 0" style="line-height: 60px;">举报图片：</div>
								<viewer v-if="scope.row.image.length > 0" style="padding: 10px;">
									<img v-for="image in scope.row.image" style="width: 45px;height: 45px;margin:0 5px;"
										:src="image.image">
								</viewer>
							</div>
						</template>
						<div v-if="scope.row.video != ''" class="d-flex">
							举报视频：<span><a target="_blank" :href="scope.row.video">{{scope.row.video}}</a></span>
						</div>
						<div>
							四加一措施检查
						</div>
						<div class="d-flex">
							<div>是否开设逃生通道：<span><template v-if="scope.row.is_escape == 10"><span
											style="color:red;">否</span></template><template v-else>是</template></span>
							</div>
							<div class="ml-3">是否落实防火分隔：<span><template v-if="scope.row.is_separate == 10"><span
											style="color:red;">否</span></template><template v-else>是</template></span>
							</div>
							<div class="ml-3">是否安装独立烟感：<span><template v-if="scope.row.is_smoke == 10"><span
											style="color:red;">否</span></template><template v-else>是</template></span>
							</div>
							<div class="ml-3">是否配备防毒面罩：<span><template v-if="scope.row.is_defense == 10"><span
											style="color:red;">否</span></template><template v-else>是</template></span>
							</div>
							<div class="ml-3">是否开展宣传培训：<span><template v-if="scope.row.is_train == 10"><span
											style="color:red;">否</span></template><template v-else>是</template></span>
							</div>
							
						</div>
						<el-divider v-if="scope.row.status == 20"></el-divider>
						<div class="d-flex" v-if="scope.row.status == 20">
							<div style="font-weight: 800;font-size: 15px;">整改情况</div>
						</div>
						<div class="d-flex" v-if="scope.row.status == 20">
							<div>是否住人：<span><template v-if="scope.row.is_person == 10"><span
											style="color:red;">否</span></template><template v-else>是</template></span>
							</div>
							<div class="ml-3">开设逃生通道口：{{scope.row.exits}} 个</div>
							<div class="ml-3">落实防火分隔：{{scope.row.fireproofing}} 处</div>
							<div class="ml-3">安装独立烟感：{{scope.row.smoke}} 个</div>
							<div class="ml-3">配备防毒面罩：{{scope.row.defense}} 个</div>
							<div class="ml-3">开展宣传培训：{{scope.row.train}} 人</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" width="120" prop="place_report_id" label="序号"></el-table-column>
				<!-- <el-table-column align="center" label="用户头像" width="110">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="昵称">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.user.nickName}}</span>
					</template>
				</el-table-column> -->
				<el-table-column align="center" label="单位">
					<template slot-scope="scope">
						<template v-if="scope.row.is_allocation == 20">
							<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
							<template v-if="scope.row.second_unit_name != ''">
								<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
							</template>
							<template v-if="scope.row.third_unit_name != ''">
								<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
							</template>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="场所名称">
					<template slot-scope="scope">
						<span>{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="户主姓名">
					<template slot-scope="scope">
						<span>{{scope.row.real_name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="户主手机号">
					<template slot-scope="scope">
						<span>{{scope.row.mobile}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="状态">
					<template slot-scope="scope">
						<template v-if="scope.row.status == 10">
							<el-tag type="danger">未处理</el-tag>
						</template>
						<template v-else-if="scope.row.status == 20">
							<el-tag type="warning">整改</el-tag>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" width="160" label="日期">
					<template slot-scope="scope">
						<span>{{scope.row.date}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="openDialog(scope.row)" size="mini" class="table-btn">操作处理
						</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="操作管理" :visible.sync="dialogVisible" top="3vh" max-width="650px" height="60%">
				<!-- 添加/修改规则 -->
				<el-form :rules="formRules" ref="form" :model="form" label-width="140px">
					<el-form-item label="状态" prop="status">
						<el-radio v-model="form.status" :value="10" :label="10">
							未处理
						</el-radio>
						<el-radio v-model="form.status" :value="20" :label="20">
							整改
						</el-radio>
					</el-form-item>
					<el-form-item label="是否住人" prop="is_person">
						<el-radio v-model="form.is_person" :value="10" :label="10">
							否
						</el-radio>
						<el-radio v-model="form.is_person" :value="20" :label="20">
							是
						</el-radio>
					</el-form-item>
					<!-- <el-form-item label="整改结果" prop="change">
						<app-rich-text :key="changeTimer" v-model="form.change"></app-rich-text>
					</el-form-item> -->
					<el-form-item label="开设逃生通道口" prop="exits">
						<el-input v-model="form.exits"></el-input>
					</el-form-item>
					<el-form-item label="落实防火分隔（处）" prop="fireproofing">
						<el-input v-model="form.fireproofing"></el-input>
					</el-form-item>
					<el-form-item label="安装独立烟感（个）" prop="smoke">
						<el-input v-model="form.smoke"></el-input>
					</el-form-item>
					<el-form-item label="配备防毒面罩（个）" prop="defense">
						<el-input v-model="form.defense"></el-input>
					</el-form-item>
					<el-form-item label="开展宣传培训（人）" prop="train">
						<el-input v-model="form.train"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitForm('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
			<app-map ref="appMap" :lat="latitude" :long="longitude"></app-map>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	import appMap from '@/components/app-map.vue';
	export default {
		inject: ['layout'],
		components: {
			appMap,
			appRichText
		},
		data() {
			return {
				lists: [],
				latitude: "",
				longitude: "",
				changeTimer: new Date().getTime(),
				dialogVisible: false,
				form: {
					place_report_id: 0,
					status: 20,
					is_person: 10,
					change: '',
					exits: '',
					fireproofing: '',
					smoke: '',
					defense: '',
					train: '',
				},
				time: [],
				formRules: {
					unit_id: [{
						required: true,
						message: '请输选择单位',
						trigger: 'change'
					}],
				},
				search: {
					s: 'store/place.report/index',
					keyword: '',
					status: 0,
					is_allocation: 0,
					page: 1,
					start_time: null,
					end_time: null,
				},
			}
		},
		created() {
			this.getList();
			this.getUnitList();
		},
		methods: {
			showMap(item) {
				this.latitude = item.latitude;
				this.longitude = item.longitude;
				this.$refs.appMap.dialogVisible = !this.$refs.appMap.dialogVisible
			},
			// 打开弹出层
			openDialog(item) {
				// 显示弹出层
				this.dialogVisible = true
				this.form.place_report_id = item.place_report_id
				this.form.is_person = item.is_person
				this.form.change = item.change
				this.form.exits = item.exits
				this.form.fireproofing = item.fireproofing
				this.form.smoke = item.smoke
				this.form.defense = item.defense
				this.form.train = item.train
				this.changeTimer = new Date().getTime()
			},
			// 提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/place.report/edit',
								place_report_id: this.form.place_report_id
							},
							data: this.form,
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.getList();
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.dialogVisible = false;
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
			handleExport() {
				let params = {
					s: 'store/place.report/export',
					keyword: this.search.keyword,
					status: this.search.status,
					is_allocation: this.search.is_allocation,
					start_time: this.search.start_time,
					end_time: this.search.end_time,
				};
				this.axios({
					token: true,
					params: params,
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						window.location.href = this.$conf.base_url + 'place_report_list.xls'
					}
				}).catch(err => {})
			},
			changeTime() {
				if (this.time) {
					this.search.start_time = this.time[0];
					this.search.end_time = this.time[1];
				} else {
					this.search.start_time = null;
					this.search.end_time = null;
				}
				this.toSearch();
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.unitList = res.data.data

				}).catch(err => {});
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该举报吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/place.report/delete',
							place_report_id: data.place_report_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>
	.el-table tr {
		background-color: #fafafa;
	}
</style>
